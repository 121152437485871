<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      height="140"
      v-if="access"
    >
      <router-link to="/">
        <v-img
            alt="COS Ohlsen Logo"
            :src="require('@/assets/logo.png')"
            transition="scale-transition"
            :max-width="width"
            height="140"
            contain
        />
      </router-link>

      <v-spacer class="d-none d-sm-block" />

      <v-btn
        href="https://cos-ohlsen.de/"
        target="_blank"
        text
        class="d-none d-sm-block"
        rel="noreferrer"
      >
        <span class="mr-2">ZUR HOMEPAGE</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main v-if="access">
      <router-view />
    </v-main>

    <v-dialog :value="!access" max-width="500">
      <v-card>
        <v-card-title>Zugriff zur Website</v-card-title>
        <v-container>
          <v-text-field label="Passwort eingeben" v-model="password" type="password" @keypress.enter="login" />
          <v-btn color="primary" block @click="login">Freischalten</v-btn>
        </v-container>
      </v-card>
    </v-dialog>

    <v-footer v-if="access">
      <v-row>
        <v-col class="text-center">
          <v-btn text href="https://www.cos-ohlsen.de/index.php/impressum" target="_blank" rel="noreferrer">Impressum</v-btn>
        </v-col>
        <v-col class="text-center">
          <v-btn text href="https://www.cos-ohlsen.de/index.php/impressum/datenschutzerklaerung" target="_blank" rel="noreferrer">Datenschutz</v-btn>
        </v-col>
      </v-row>
      <v-container class="text-center">
        <span>© 2021 COS Ohlsen GmbH</span>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    password: '',
    access: true
  }),

  computed: {
    width () {
      return document.body.clientWidth - 32
    }
  },

  methods: {
    login () {
      if (this.password === 'ABC') {
        this.access = true
      }
    }
  }
};
</script>
